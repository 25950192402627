<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat dense>
          <v-toolbar-title>Create New Incoming Payment</v-toolbar-title>
          <v-col>
          <v-btn
            icon
            @click="printDoc"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>

        </v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary" small text to="/banking/incoming-payments">
            <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- editor -->
          <editor :editMode="editMode" :tenantData="tenantData" :currencies="currencies" :initial="dataItem" @data="save"></editor>
          <!-- end -->
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  components: {
    Editor
  },
  computed: {
    ...mapGetters(["user"])
  },
  data: () => ({
    dataItem: { parent: false, inv1: [] },
    tenantData: {},
    editMode: false,
    currencies: []
  }),
  methods: {
    save(data) {
      const url = "/incoming_payment";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then(res => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/banking/incoming-payments");
        })
        .catch(err => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then(res => {
          self.currencies = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
       printDoc() {
      var doc = new jsPDF({ compress: true });

      doc.setFont("Georgia, serif", "bold");
      //doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);

      // Calculate Page Width
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      //  var totalPagesExp = "{total_pages_count_string}";
      //CG LOGO
      var img = new Image();
      img.src = require("@/assets/logocg.png");
      doc.addImage(img, "png", 10, 11, 50, 40);

      doc.text("Incoming Payment", pageWidth / 2, 10, "center");
      doc.line(5, 11, pageWidth - 5, 11); // Top Line
      doc.setFontSize(10);
      doc.text("Original", pageWidth - 20, 14);

      doc.setFontSize(9);
      doc.text(
        "CAR &GENERAL TRADING LIMITED-NAIROBI BRANCH",
        pageWidth / 2 - 35,
        16
      );

      doc.setFontSize(8);
      doc.text("P.O BOX 20001-00200 NAIROBI", pageWidth / 2 - 35, 25);
      doc.text("PIN: P051096263G", pageWidth / 2 - 35, 28);
      doc.text("VAT NO:0108334E", pageWidth / 2 - 35, 31);
      doc.line(5, 40, pageWidth - 5, 40); // Line Below Image

      function headRowsCustDetails() {
        return [
          {
            count: "",
            name: "",
          },
        ];
      }

      const cust_details = [];
      const custTitle = {
        count: "Received From:",
      };
    
      cust_details.push(custTitle);
      
      //Delivery Address
      const custAddres2 = {
        count: " Address:",
      };
      
      cust_details.push(custAddres2);     
      //Customer Detials
      doc.autoTable({
        head: headRowsCustDetails(),
        body: cust_details,
        theme: "plain",
        tableWidth: 80,
        columnStyles: {
          count: {
            fontStyle: "bold",
          },
        },
        startY: 33,
        styles: {
          overflow: "hidden",
          font: "Tahoma, Verdana, Segoe, sans-serif",
          fontSize: 9,
        },
        margin: { right: 100 },
      });
      function headRowsRefDetails() {
        return [
          {
            count: "",
            name: "",
          },
        ];
      }

      const doc_details = [];

      const docNumber = {
        count: "Document Number"
       
      };

      const docDate = {
        count: "Document Date",
      
      };
      const reference = {
        count: "Your Reference",
       
      };
      const contactPerson = {
        count: "Contact Person",
       
      };

      doc_details.push(docNumber);
      doc_details.push(docDate);
      doc_details.push(reference);
      doc_details.push(contactPerson);

      doc.autoTable({
        head: headRowsRefDetails(),
        body: doc_details,
        theme: "plain",
        tableWidth: 80,
        columnStyles: {
          count: {
            fontStyle: "bold",
            halign: "right",
          },
        },
        startY: 33,
        styles: {
          overflow: "hidden",
          font: "Tahoma, Verdana, Segoe, sans-serif",
          fontSize: 9,
        },
        margin: { left: 120 },
      });

      //--------------------------------------

      const feenote_details = [];

      function headRowsItemDetails() {
        return [
          {
            count: "#",
            itemCode: "ItemCode",
            description: "Description",
            Quantity: "Quatity",
            Price: "Price",
            Disc: "Disc %",
            Tax: "Tax %",
            total_amount: "Total",
          },
        ];
      }

      /* for (const note of this.saleItemsData) {
        const fee_note_date = {
          count: note.LineNum,
         
        };
        feenote_details.push(fee_note_date);
      } */

      doc.autoTable({
        head: headRowsItemDetails(),
        body: feenote_details,
        theme: "grid",
        columnStyles: {
          total_amount: {
            fontStyle: "bold",
            halign: "right",
          },
          unit_price: {
            fontStyle: "bold",
            halign: "right",
          },
        },
        styles: {
          font: "Tahoma, Verdana, Segoe, sans-serif",
          cellPadding: 1,
          fontSize: 9,
        },
        startY: doc.autoTable.previous.finalY + 20,
      });

      //footer 2
      const doc_footer = [];

      const docTotBTax = {
        count: "Total Before Tax",
      
      };
      const totalTax = {
        count: "Total Tax Amount",
        
      };
      const totalAmount = {
        count: "Total Amount",
       
      };

      doc_footer.push(docTotBTax);
      doc_footer.push(totalTax);
      doc_footer.push(totalAmount);

      doc.autoTable({
        head: headRowsRefDetails(),
        body: doc_footer,
        theme: "plain",
        tableWidth: 80,
        columnStyles: {
          count: {
            fontStyle: "bold",
            halign: "right",
          },
        },
        startY: doc.lastAutoTable.finalY + 25,
        styles: {
          overflow: "hidden",
          font: "Tahoma, Verdana, Segoe, sans-serif",
          fontSize: 9,
        },
        margin: { left: 120 },
      });

      // footer 1
      const doc_footer_1 = [];

      const Remarks = {
        count: "Remarks",
      };
      const Comments = {
        count: "Comments",
       
      };

      doc_footer_1.push(Remarks);
      doc_footer_1.push(Comments);

      doc.autoTable({
        head: headRowsCustDetails(),
        body: doc_footer_1,
        theme: "plain",
        tableWidth: 80,
        columnStyles: {
          count: {
            fontStyle: "bold",
          },
        },
        startY: doc.lastAutoTable.finalY  ,
        styles: {
          overflow: "hidden",
          font: "Tahoma, Verdana, Segoe, sans-serif",
          fontSize: 9,
        },
        margin: { right: 100 },
      });

      // approvals
      const doc_approval = [];

      const preparedby = {
        count: "Prepared by ________________________________",
        name: "Date_____________",
      };
      const approvedby = {
        count: "Approved by ________________________________",
        name: "Date_____________",
      };
      const receivedby = {
        count: "Received by ________________________________",
      };
      doc_approval.push(preparedby);
      doc_approval.push(approvedby);
      doc_approval.push(receivedby);

      doc.autoTable({
        head: headRowsCustDetails(),
        body: doc_approval,
        theme: "plain",
        tableWidth: 100,
        columnStyles: {
          count: {
            fontStyle: "bold",
          },
          name: {
            fontStyle: "bold",
          },
        },
        startY: doc.lastAutoTable.finalY ,
        styles: {
          overflow: "hidden",
          font: "Tahoma, Verdana, Segoe, sans-serif",
          fontSize: 9,
        },
        margin: { right: 100 },
      });

      const doc_note = [];

      const note = {
        count:
          "Payments above ksh 1M(One Million) or US$ 10,000/ is paid through Real(RTGS) with effect from OCT09.",
      };
      const note2 = {
        count:
          "Bank Details STANDARD CHARTERED BANK, Moi Avenue,A/C 0104003231500, Currency: KES",
      };

      doc_note.push(note);
      doc_note.push(note2);
      doc.autoTable({
        head: headRowsCustDetails(),
        body: doc_note,
        theme: "plain",

        columnStyles: {
          count: {
            fontStyle: "bold",
          },
        },
        startY: doc.lastAutoTable.finalY ,
        styles: {
          overflow: "hidden",
          font: "Tahoma, Verdana, Segoe, sans-serif",
          fontSize: 9,
        },
      });

      doc.output("dataurlnewwindow");
    },
  },
  created() {
    this.getCurrencies();
  }
};
</script>